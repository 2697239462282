// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #08162A;
$primary_alt: #3275B3;

$button: #153563;
$social-button: #979797;

$accent: #C7D739;
$accent_alt: #CEDD42;

$light: #FFFFFF;
$light_alt: #EEEEEE;

$dark: #000000;
$dark_alt: #1C1C1C;

$foreground: #B6B6B6;
$foreground_alt: #8B8B8B;

$background: #F1F1F1;
$background_alt: #D8D8D8;
$background_tertiary: #F9F9F9;

$page_background: #FFFFFF;
$page_background_alt: #D0D0D0;

$warning: #A9423B;
$warning_alt: #F83636;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "rosella-solid", serif;
$heading-font-accent: "rosella-hatched", serif;
$heading-font-alt: "DM Sans", sans-serif;
$body-font: "DM Sans", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1292;
$column-gap: 50;
$content-padding: 30;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    header: 5,
    navigation: 4,
    hero: 3,
    content: 2,
    footer: 1,
);
