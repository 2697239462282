// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    & {
        color: $primary;
        font-family: $heading-font;
        font-size: remify(20, 16);
        font-weight: 400;
        letter-spacing: 0;
        line-height: remify(28, 23.005);
        margin: remify(0 0 10, 23.005);
        text-transform: uppercase;
    }
}

// Tablet variant

@media screen and (min-width: $xs-break) {
    .title {
        & {
            font-size: remify(35, 16);
            line-height: remify(41, 23.005);
            margin: remify(0 0 10, 23.005);        }
    }
}
