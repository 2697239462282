// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Columns
\* ------------------------------------------------------------------------ */

@for $i from 2 through 12 {
    .columns--#{$i} {
        columns: $i;
    }

}

.columns {
    margin-bottom: remify(60, 16);
}

.columns ul,
.columns ol  {
    margin-bottom: 0;
}
