// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Ticket Block Styles
\* ------------------------------------------------------------------------ */

:root {
    --corner-radius: 24px;
}

.ticket-block {
    .inner {
        background-color: $primary;
        background-image: url("../media/ticket-texture.jpg");
        background-position: center center;
        background-size: cover;
    }
}

.ticket-section {
    & {
        background-size: cover;
        color: $light;
        margin: 0 auto;
        text-align: center;
        position: relative;
        z-index: 2;
        max-width: remify($site-width + 70);
        width: 100%;
    }
}

.ticket-section h2 {
    color: $light;
    font-family: $heading-font-accent;
    font-size: remify(25, 16);
    line-height: remify(20.48, 16);
    margin-bottom: remify(16, 16);
}

.ticket-section p {
    color: $light;
    font-size: remify(16, 16);
    line-height: remify(25, 16);
}

.ticket-button {
    background-color: $button;
    width: remify(255, 16);
}

.ticket-content {
    margin: 0px;
}

// tablet to desktop
@media screen and (min-width: $xxl-break) {
    .ticket-block {
        & {
            padding: remify(0 16);
        }

        p:last-child {
            margin-bottom: 0;
        }

         p {
            color: $light;
            font-size: remify(20, 16);
            line-height: remify(31, 20);
        }

        .outer {
            padding: remify(8);
            position: relative;
        }

        .inner {
            height: 100%;
            mask: radial-gradient(var(--corner-radius) at var(--corner-radius) var(--corner-radius), transparent 98%, #000000) calc(var(--corner-radius) * -1) calc(var(--corner-radius) * -1);
            position: relative;
            width: 100%;
            z-index: 1;
        }

        .border {
            background: #FFFFFF;
            height: 100%;
            mask: radial-gradient(var(--corner-radius) at var(--corner-radius) var(--corner-radius), transparent 98%, #000000) calc(var(--corner-radius) * -1) calc(var(--corner-radius) * -1);
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
        }

        .content_post {
            padding: remify(57 85);
        }
    }

    .ticket-section {
        border: none;
        margin-top: remify(-120, 16);
    }

    .ticket-content {
        margin: 0;
    }

    .ticket-section h2 {
        font-size: remify(35, 16);
        line-height: remify(18.7429, 16);
    }

    .ticket-button {
        width: auto;
        margin-bottom: remify(8, 16);
    }
}
