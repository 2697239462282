// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Search Form
\* ------------------------------------------------------------------------ */

.search-form_container {
    & {
        margin: remify(0 0 20, 16);
    }
}

.search-form {
    & {
        position: relative;
    }
}

.search-form_label {
    & {
    }
}

.search-form_input {
    & {
        background: $background_alt;
        border: 0;
        border-radius: remify(7, 16);
        color: $primary;
        font-family: $body-font;
        font-size: remify(16, 16);
        margin: 0;
        outline: none;
        padding: remify(8 38 8 12, 16);
        transition: border-color 0.15s;
        width: remify(457, 16);
        height: remify(42, 16);
        float: right;
        opacity: .36;
    }

    &:focus,
    &:hover {
        border-color: $primary;
    }
}

.search-form_button {
    & {
        background: none;
        border: 0;
        bottom: remify(1);
        color: $social-button;
        font-weight: 700;
        cursor: pointer;
        font-size: remify(16, 16);
        margin: 0;
        outline: none;
        padding: remify(10 12, 16);
        position: absolute;
        right: remify(1);
        top: remify(1);
        transition: color 0.15s;
    }

    &:focus,
    &:hover {
        color: $primary_alt;
    }
}

#mobile__search__toggle {
    .icon__hidden {
        display: none;
    }
}

.mobile__search-form {
    & {
        left: -999em;
        position: absolute;
        top: remify(30);
        width: calc(100% - 120px);
    }

    .mobile-search-open > & {
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
    }

    .search-form_input {
        width: 100%;
        opacity: 1;
    }

    @media screen and (min-width: $l-break) {
        & {
            display: none;
        }
    }
}
