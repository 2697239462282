// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Page
\* ------------------------------------------------------------------------ */

html,
body {
    & {
        background: $primary;
        height: 100%;
        width: 100%;
    }
}

.page_container {
    & {
        min-height: 100%;
        position: relative;
        width: 100%;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background: $light;
    }
}
