// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    @media screen and (max-width: remify(($site-width + 100), 16)) {
        font-size: (16 / ($site-width + 100)) * 100vw;
    }
}

.header_inner {
    & {
        padding-bottom: remify($content-padding);
        padding-top: remify(($content-padding * 0.666666666666667));
        height: auto;
    }
}

.header_logo {
    & {
        margin: remify(20 0 0);
        width: remify(213, 16);
        margin-bottom: remify(-80, 16);
    }
}

.header_row {
    flex-wrap: nowrap;
    align-items: center;
}

// fullbleed varaint

.header-block.-fullbleed {
    > .header_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
