// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

.menu-list_container {
    & {
        @extend %clearfix;
    }
}

.menu-list {
    & {
        @extend %clearfix;

        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
    }
}

.menu-list_item {
    & {
        float: left;
        position: relative;
    }

    &.-parent {
        display: flex;
        flex-wrap: wrap;
    }

    &.-mega {
        position: static;
    }
}

.menu-list_link {
    & {
        display: block;
        flex: 1 1 auto;
        text-decoration: none;
    }
}

.menu-list_toggle {
    & {
        background: none;
        border: 0;
        cursor: pointer;
        display: block;
        margin: 0;
    }

    > .icon {
        transition: transform 0.15s;
    }

    .menu-list_item.is-active > & > .icon {
        transform: rotate(-180deg);
    }
}

// center variant

.menu-list.-center {
    & {
        float: right;
        position: relative;
        right: 50%;
    }

    > .menu-list_item {
        left: 50%;
    }
}

// flex variant

.menu-list.-flex {
    & {
        display: flex;
    }

    > .menu-list_item {
        flex: 1 1 auto;
        width: 0;
    }
}

// vertical variant

.menu-list.-vertical {
    & {
        text-align: left;
    }

    &.-right {
        text-align: right;
    }

    > .menu-list_item {
        float: none;
    }
}

// tier variant

.menu-list[class*="-tier"] {
    & {
        min-width: 100%;
    }

    // accordion variant

    &.-accordion {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.25s, visibility 0s 0.25s;
        visibility: hidden;
        width: 100%;
    }

    .menu-list_item.-parent.is-active > &.-accordion {
        max-height: remify(500, 16);
        transition: max-height 0.25s;
        visibility: visible;
    }

    // overlay variant

    &.-overlay {
        left: -999em;
        opacity: 0;
        position: absolute;
        transition: left 0s 0.15s, opacitwy 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        top: -999em;
        visibility: hidden;
        width: remify(250, 16);
    }

    .menu-list_item.-parent:hover > &.-overlay,
    .menu-list_item.-parent.is-active > &.-overlay {
        opacity: 1;
        transition: opacity 0.15s;
        visibility: visible;
    }

    // overlay, reverse variant

    &.-overlay.-reverse {
        transition: opacity 0.15s, right 0s 0.15s, top 0s 0.15s;
    }

    .menu-list_item.-parent:hover > &.-overlay.-reverse,
    .menu-list_item.-parent.is-active > &.-overlay.-reverse {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }
}

// tier1 variant

.menu-list.-tier1 {
    // overlay variant
    & {
        font-size: remify(14, 16);
        margin-left: remify(16);
    }

    .menu-list_item.-parent:hover > &.-overlay,
    .menu-list_item.-parent.is-active > &.-overlay {
        left: 0;
        top: 100%;
    }

    &.-overlay > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(-90deg);
    }

    // overlay, reverse variant

    &.-overlay.-reverse {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &.-overlay.-reverse,
    .menu-list_item.-parent.is-active > &.-overlay.-reverse {
        left: auto;
        right: 0;
    }

    &.-overlay.-reverse > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(90deg);
    }
}

// tier2 variant

.menu-list.-tier2 {
    // overlay variant

    .menu-list_item.-parent:hover > &.-overlay,
    .menu-list_item.-parent.is-active > &.-overlay {
        left: 100%;
        top: 0;
    }

    // overlay, reverse variant

    &.-overlay.-reverse {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &.-overlay.-reverse,
    .menu-list_item.-parent.is-active > &.-overlay.-reverse {
        left: auto;
        right: 100%;
    }
}

// mega variant

.menu-list_container.-mega {
    & {
        display: flex;
        left: -999em;
        opacity: 0;
        position: absolute;
        right: 999em;
        top: -999em;
        transition: left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
    }

    .menu-list_item.-mega.-parent:hover > &,
    .menu-list_item.-mega.-parent.is-active > & {
        left: 0;
        opacity: 1;
        top: 100%;
        transition: opacity 0.15s;
        right: 0;
        visibility: visible;
    }

    > .menu-list.-tier1 {
        flex: 1 1 auto;
        min-width: 0;
    }
}

// navigation variant

.menu-list.-navigation {
    & {
        font-family: $body-font;
        font-size: remify(18, 16);
        font-weight: 700;
    }

    &.-vertical:before,
    &.-vertical:after {
        clear: both;
        content: "\0020";
        display: block;
        height: remify(12, 17);
        width: 100%;
    }

    .menu-list_link {
        color: $primary;
        padding: remify(12 16, 17);
    }

    .menu-list_link:focus,
    .menu-list_link:hover,
    > .menu-list_item.-parent:hover > .menu-list_link,
    > .menu-list_item.-parent.is-active > .menu-list_link {
        color: $primary_alt;
    }

    .menu-list_toggle {
        color: $light;
        font-size: 1em;
        padding: remify(12, 17);
        transition: color 0.15s;
    }

    .menu-list_toggle:focus,
    .menu-list_toggle:hover,
    .menu-list_item.-parent:hover >.menu-list_toggle,
    .menu-list_item.-parent.is-active >.menu-list_toggle {
        color: $primary_alt;
    }

    .menu-list_container.-mega,
    .menu-list.-tier1.-accordion,
    .menu-list.-tier1.-overlay {
        background-color: $primary;
    }

    .menu-list.-tier1 .menu-list_link {
        color: $light;
    }

    .menu-list.-tier1 .menu-list_link:focus,
    .menu-list.-tier1 .menu-list_link:hover,
    .menu-list.-tier1.-overlay .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier1.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
        color: $primary_alt;
    }

    .menu-list.-tier1 .menu-list_toggle {
        color: $light;
    }

    .menu-list.-tier1 .menu-list_toggle:focus,
    .menu-list.-tier1 .menu-list_toggle:hover,
    .menu-list.-tier1 .menu-list_item.-parent:hover >.menu-list_toggle,
    .menu-list.-tier1 .menu-list_item.-parent.is-active >.menu-list_toggle {
        color: $primary_alt;
    }

    .menu-list.-tier2.-accordion,
    .menu-list.-tier2.-overlay {
        color: $primary_alt;
    }

    .menu-list.-tier2 .menu-list_link {
        color: $light;
    }

    .menu-list.-tier2.-accordion .menu-list_link:focus,
    .menu-list.-tier2.-accordion .menu-list_link:hover,
    .menu-list.-tier2.-overlay .menu-list_link:focus,
    .menu-list.-tier2.-overlay .menu-list_link:hover,
    .menu-list.-tier2.-overlay .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier2.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
        color: $primary_alt;
    }

    .menu-list_container.-mega .menu-list.-tier2 .menu-list_link:before {
        content: "\002D\0020\0020";
    }
}

// social variant

.menu-list.-social {
    & {
        font-size: remify(30, 16);
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(12, 30);
    }

    .menu-list_link {
        color: $primary;
        width: remify(36, 30);
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: $primary_alt;
    }
}

// sitemap variant

.menu-list.-sitemap {
    & {
        font-size: remify(16, 16);
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(32, 16);
    }

    .menu-list_link {
        color: $primary_alt;
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: $primary_alt;
    }
}

// meta variant

.menu-list.-meta {
    & {
        color: $foreground_alt;
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 700;
        text-transform: uppercase;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(20, 14);
    }

    .menu-list_link {
        color: $foreground_alt;
        display: inline-block;
    }

    .menu-list_link:hover {
        color: lighten($foreground_alt, 10);
    }
}

// pagination variant

.menu-list.-pagination {
    & {
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(8, 14);
    }

    .menu-list_link.-current {
        color: $foreground;
        cursor: auto;
    }
}
