// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
        background: $primary;
        margin-top: auto;
    }
}

.footer_inner {
    & {
        background: $primary;
        margin: 0 auto;
        max-width: remify(1195);
        padding: remify(($content-padding * 2) $content-padding ($content-padding * 4) $content-padding);
        width: 100%;
    }
}

.footer_link {
    & {
        color: $light;
    }

    &:focus,
    &:hover {
        color: $primary_alt;
    }
}

.footer_title {
    & {
        color: $primary_alt;
        font-size: remify(20, 16);
        font-family: $heading-font-alt;
        font-weight: 700;
        margin-bottom: remify(11.245, 22.49);
        margin-top: 0;
        text-align: center;
    }
}

.footer_text {
    & {
        color: $light;
        font-size: remify(16, 16);
        line-height: remify(17.245, 15);
        text-align: center;
        margin-bottom: remify(30, 15);
    }
}

.footer_logo {
    & {
        margin: 0 auto;
        width: remify(192, 16);
    }
}

.footer_logo_right {
    width: remify(128, 16);
}

.footer_right {
    & {
        background-color: $light;
        border-radius: remify(7, 16);
        padding: remify(32, 16);
        text-align: center;
        width: remify(283, 16);
        margin: 0 auto;
    }
}

.footer_right_text {
    & {
        color: $dark;
        font-size: remify(14, 16);
        padding-top: remify(24, 16);
        margin-bottom: 0;
    }
}

// fullbleed variant

.footer-block.-fullbleed {
    & {
        background: $primary;
        padding: remify(($content-padding * 1.25) $content-padding);
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}

.footer_row > [class*="col-"] {
    margin-bottom: remify(32);
}
