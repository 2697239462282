// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

// callout variant

.widget.-callout {
    & {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 0;
        margin: remify(10 0);
        padding-bottom: (390 / 570) * 100%;
        position: relative;
        width: 100%;
        height: remify(268, 16);

    &:hover {
        opacity: .7;
    }
}

    .widget_link {
        & {
            bottom: 0;
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .widget_title {
        color: $light;
        font-size: remify(30, 16);
        line-height: remify(18.6667, 16);
        letter-spacing: 0;
        left: 0;
        padding-left: remify(8, 30);
        padding-right: remify(8, 30);
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;

        @supports (transform: translateX(-50%) translateY(-50%)) {
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

        .widget-overlay {
            background: $primary;
            opacity: 85%;
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            padding-top: remify(48, 16);
            padding-bottom: remify(48, 16);
            transform: translate(-50%, -50%);
        }
}
