// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }
}

.header_inner {
    & {
        margin: 0 auto;
        max-width: remify($site-width + 120);
        padding-top: remify(40, 16);
        padding-left: remify(29.6, 16);
        padding-right: remify(29.6, 16);
        height: remify(103, 16);
    }
}

.header_link {
    & {
        color: $primary;
    }

    &:focus,
    &:hover {
        color: $primary_alt;
    }
}

.header_title {
    & {
        color: $primary;
        font-size: remify(17, 16);
        text-transform: uppercase;
    }
}

.header_logo {
    & {
        border: remify(4, 16) solid $light;
        border-radius: 50%;
        margin: remify(0 auto);
        margin-top: remify(-52, 16);
        width: remify(158, 16);
    }
}

.header_menu-list_container {
    .menu-list.-navigation {
        display: flex;
        justify-content: space-between;
        margin-right: remify(-16.941);
        margin-top: remify(12, 17.23);
        transform: translateX(#{remify(24, 17.23)});
    }

    .menu-list.-social {
        font-size: remify(25, 16);
        margin: remify(0 0 0 25, 25);
    }

    .menu-list.-social .menu-list_link {
        color: $social-button;
    }

    .menu-list.-social .menu-list_link:focus,
    .menu-list.-social .menu-list_link:hover {
        color: $primary_alt;
    }
}

.header_inner .toolbar_menu-list_container {
    .menu-list.-social {
        font-size: remify(20, 16);
    }
}

// fullbleed varaint

.header-block.-fullbleed {
    & {
        background: $light;
        padding: 0;
    }


    > .header_inner {
        background: none;
        border-bottom: 0;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}

// internal varaint

.header-block.-internal {
    box-shadow: 0 35px 33px 28px $background_alt;
}
