// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: $primary;
        border: 0;
        border-radius: remify(6, 16);
        color: $light;
        cursor: pointer;
        display: inline-block;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 400;
        line-height: remify(20, 14);
        outline: 16;
        letter-spacing: 0;
        margin: remify(0 3.5 14, 14);
        padding: remify(10 20, 14);
        text-decoration: none;
        transition: background 0.15s;
    }

    &:focus,
    &:hover {
        background: $primary_alt;
    }
}

// Desktop variant

@media screen and (min-width: $xs-break) {
    .button {
        font-size: remify(16, 16);
    }
}
