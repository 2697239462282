// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * User Content
\* ------------------------------------------------------------------------ */

.user-content {
    & {
        @extend %clearfix;
    }

    // links

    a:not(.button) {
        color: $primary_alt;
        font-weight: 700;
        text-decoration: none;
        transition: color 0.15s;
    }

    a:not(.button):hover {
        color: $foreground;
    }

    // titles

    h1,
    h2,
    h4,
    h5,
    h6 {
        color: $primary;
        font-family: $heading-font;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: uppercase;
    }

    h1 {
        font-size: remify(20, 16);
        line-height: remify(28, 20);
        margin: remify(0 0 30, 20);
    }

    h2 {
        font-size: remify(18, 16);
        line-height: remify(24, 18);
        margin: remify(0 0 20, 18);
    }

    h3 {
        color: $primary;
        font-family: $heading-font-alt;
        font-size: remify(20, 16);
        line-height: remify(22, 20);
        font-weight: 700;
        letter-spacing: remify(0, 20);
        margin: remify(0 0 20, 20);
    }

    h4,
    h5,
    h6 {
        font-size: remify(18, 16);
        line-height: remify(21, 18);
    }

    // text

    p,
    ol,
    ul,
    table {
        color: $dark;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 400;
        line-height: remify(25, 16);
        margin: remify(0 0 60, 16);
    }

    ol {
        padding-left: remify(25.134375, 16);
    }

    ol li {
        padding-left: remify(16.75625, 16);
    }

    ol li::marker {
        color: $foreground_alt;
        font-weight: 700;
    }

    ul {
        list-style: none;
        padding-left: remify(0, 16);
    }

    ul li {
        background-image: url("../media/oda-check.svg");
        background-position: left remify(3.35125, 16);
        background-repeat: no-repeat;
        background-size: remify(15.080625, 16);
        padding-left: remify(41.89062, 16);
    }

    ul li:not(:last-child),
    ol li:not(:last-child) {
        margin-bottom: remify(16.75625, 16);
    }

    ol ol,
    ol ul,
    ul ol,
    ul ul {
        font-size: 1em;
    }

    .table_container {
        overflow: auto;
        width: 100%;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    .table_container table {
        min-width: remify(720, 16);
    }

    table td,
    table th {
        border: none;
        font-size: remify(18, 20);
        line-height: remify(22, 18);
        padding: remify(8 25, 18);
        text-align: left;
        vertical-align: top;
    }

    table tbody th,
    table > tr > th,
    table[data-stacked="true"] tbody td:before {
        font-weight: 700;
        text-transform: uppercase;
    }

    table thead td,
    table thead th {
        background: $primary;
        color: $light;
        font-weight: 700;
        padding: remify(14 25, 18);
    }

    table > tr td,
    table tbody td {
        padding: remify(14 25, 18);
    }

    table > tr:nth-child(even) td,
    table > tr:nth-child(even) th,
    table tbody tr:nth-child(even) td,
    table tbody tr:nth-child(even) th {
        background: $background_tertiary;
    }

    table > tr:nth-child(even),
    table > tr:nth-child(even),
    table tbody tr:nth-child(even),
    table tbody tr:nth-child(even) {
        td:first-child,
        th:first-child {
            border-radius: remify(10 0 0 10);
        }

        td:last-child,
        th:last-child {
            border-radius: remify(0 10 10 0);
        }
    }

    // scrolling responisve tables

    @media screen and (max-width: $xs-break - (1 / 16)) {
        table {
            display: block;
            overflow: auto;
            padding-bottom: 1px;
        }
    }

    // blockquote

    blockquote {
        font-style: italic;
        margin: remify(0 0 18, 16);
        padding: remify(0 0 0 28, 16);
        position: relative;
    }

    blockquote p,
    blockquote ol,
    blockquote ul,
    blockquote table {
        color: lighten($dark, 50);
        font-size: remify(14, 16);
    }

    blockquote > :last-child {
        margin-bottom: 0;
    }

    // horizontal rule

    hr {
        background: darken($background_alt, 10);
        border: 0;
        clear: both;
        height: remify(1);
        margin: remify(0 0 20, 16);
        width: 100%;
    }

    // images

    img {
        display: block;
        height: auto !important;
        max-width: 100%;
    }

    // alignment

    .alignleft {
        float: left;
        margin-right: remify(25, 16);
        max-width: (100% / 3);
    }

    .alignright {
        float: right;
        margin-left: remify(25, 16);
        max-width: (100% / 3);
    }

    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

// Tablet variant

@media screen and (min-width: $xs-break) {
    .user-content {
        h1 {
            font-size: remify(35, 16);
            line-height: remify(41, 35);
            margin: remify(0 0 30, 35);
        }

        h2 {
            font-size: remify(26, 16);
            line-height: remify(30, 26);
            margin: remify(0 0 20, 26);
        }

        h3 {
            font-size: remify(30, 16);
            letter-spacing: 0;
            margin: remify(0 0 20, 30);
        }

        h4,
        h5,
        h6 {
            font-size: remify(22, 16);
            line-height: remify(31, 22);
        }

        p,
        ol,
        ul,
        table {
            font-size: remify(20, 16);
            font-weight: 400;
            line-height: remify(31, 20);
            margin: remify(0 0 60, 20);
        }

        ol.columns,
        ul.columns {
            columns: 3;
        }

        ol {
            padding-left: remify(58.64688, 16.75625);
        }

        ol li {
            padding-left: remify(8, 16.75625);
        }

        ol li::marker {
            color: #8B8B8B;
            font-weight: 700;
        }

        ul {
            list-style: none;
            padding-left: remify(32, 16);
        }

        ul li {
            background-position: left remify(8, 20);
            background-repeat: no-repeat;
            background-size: remify(15.080625, 20);
            padding-left: remify(33.5125, 20);
        }

        ol.columns li,
        ul.columns li {
            break-inside: avoid;
        }

        blockquote {
            margin-bottom: remify(24, 16);
            padding-left: remify(36, 16);
        }

        blockquote:before {
            font-size: remify(24, 16);
        }

        blockquote p,
        blockquote ol,
        blockquote ul,
        blockquote table {
            font-size: remify(18, 16);
        }
    }
}

// light variant

.user-content.-light {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    table,
    ul {
        color: $light;
    }
}
