// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

// callout variant

.widget.-callout {
    .widget_title {
        font-size: remify(20, 16);
    }
}
