// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Ninja Forms
\* ------------------------------------------------------------------------ */

// honeypot field fix

.nf-form-hp {
    & {
        left: -999em;
        position: absolute;
        top: -999em;
    }
}

// date field fix

.nf-field-container {
    .pikaday__container {
        display: block;
    }
}

// list field fix

.nf-field-container {
    .list-checkbox-wrap ul,
    .list-radio-wrap ul {
        font-size: remify(16, 16);
        list-style: none;
        margin-bottom: 0;
        padding: 0;
    }
}

// borders on input fields

.nf-field-container {
    .input {
        border: remify(2) solid $primary;
    }
}

// label alignment

.nf-field-container {
    // combined

    &.label-above .field-wrap,
    &.label-right .field-wrap,
    &.label-below .field-wrap,
    &.label-left .field-wrap {
        align-items: flex-start;
        display: flex;
    }

    &.label-right .field-wrap .nf-field-label,
    &.label-left .field-wrap .nf-field-label {
        flex: none;
    }

    &.label-above .field-wrap .nf-field-element,
    &.label-below .field-wrap .nf-field-element {
        width: 100%;
    }

    &.label-right .field-wrap .nf-field-element,
    &.label-left .field-wrap .nf-field-element {
        flex: 1 1 auto;
    }

    &.label-right .checkbox-wrap .nf-field-element,
    &.label-left .checkbox-wrap .nf-field-element {
        flex: none;
    }

    // above

    &.label-above .field-wrap {
        flex-direction: column;
    }

    &.label-above .field-wrap .nf-field-label {
        order: 0;
    }

    &.label-above .field-wrap .text.-label {
        margin-bottom: remify(16, 13.405);
    }

    &.label-above .field-wrap .nf-user-content {
        order: 1;
    }

    &.label-above .field-wrap .nf-user-content p {
        font-size: remify(12, 16);
        font-style: italic;
    }

    &.label-above .field-wrap .nf-user-content *:last-child {
        margin-bottom: remify(5);
    }

    &.label-above .field-wrap .nf-field-element {
        order: 2;
    }

    // below

    &.label-below .field-wrap {
        flex-direction: column;
    }

    &.label-below .nf-field-label {
        order: 2;
    }

    &.label-below .nf-field-element {
        order: 1;
    }

    // right

    &.label-right .nf-field-label {
        order: 2;
    }

    &.label-right .nf-field-element {
        order: 1;
    }

    // hidden

    &.label-hidden .nf-field-label {
        left: -999em;
        position: absolute;
        top: -999em;
    }
}

/* ------------------------------------------------------------------------ *\
 * Opinionated Tweaks
\* ------------------------------------------------------------------------ */

// list field fix

.nf-field-container {
    .list-checkbox-wrap ul,
    .list-radio-wrap ul {
        font-size: remify(16, 16);
        margin-bottom: remify(24, 16);
    }

    .list-checkbox-wrap ul .input.-checkbox,
    .list-radio-wrap ul .input.-radiobutton {
        margin-bottom: remify(4, 16);
    }

    .list-checkbox-wrap ul .text.-label,
    .list-radio-wrap ul .text.-label {
        margin-bottom: remify(4, 16);
    }
}

// touch list field fix

@media (pointer: coarse) {
    .nf-field-container {
        .list-checkbox-wrap ul li:not(:last-child) .text.-label,
        .list-radio-wrap ul li:not(:last-child) .text.-label {
            margin-bottom: remify(24, 16);
        }
    }
}

// stars field fix

.nf-field-container {
    .stars {
        margin-bottom: remify(24, 16);
    }
}

// description fix

.nf-field-container {
    .nf-user-content {
        margin-top: remify(-20, 16);
    }

    &.label-above .nf-user-content {
        margin-top: remify(-10, 16);
    }
}

// error fix

.nf-field-container {
    .nf-error-msg {
        font-size: remify(10, 16);
        margin-bottom: remify(16, 10);
        margin-top: remify(-12, 10);
    }
}

// reCAPTCHA fix

.nf-field-container {
    .g-recaptcha {
        margin-bottom: remify(24, 16);
    }
}

// label alignment

.nf-field-container {
    // below

    &.label-below .nf-field-label > .text.-label {
        margin-top: remify(4, 16);
    }

    &.label-below .nf-field-element .input,
    &.label-below .nf-field-element .stars,
    &.label-below .list-checkbox-wrap ul,
    &.label-below .list-radio-wrap ul {
        margin-bottom: 0;
        order: 1;
    }

    // left

    &.label-left .nf-field-label > .text.-label {
        margin-right: remify(10, 16);
    }

    // right

    &.label-right .nf-field-label > .text.-label {
        margin-left: remify(10, 16);
    }
}

.nf-field-element {
    ul li {
        background-image: none;
        padding-left: remify(16, 16);
    }

    .list-wrap & ul li {
        display: flex;
        flex-wrap: nowrap;
    }

    .input.-checkbox {
        margin-right: remify(16, 16);
        flex: 0 0 remify(23.866, 17.9);
    }
}

.nf-field-label {
    .text {
        color: $primary;
        font-weight: 700;
        font-size: remify(13.405, 16);

    }
}

// Tablet variant

@media screen and (min-width: $xs-break) {
    .nf-field-label {
        .text {
            font-size: remify(17.9, 16);
        }
    }

    .nf-field-container {
        &.label-above .field-wrap .nf-user-content p {
            font-size: remify(14, 16);
        }

        &.label-above .field-wrap .text.-label {
            margin-bottom: remify(10, 17.9);
        }
    }
}
